// In Next.js, this file would be called: app/providers.tsx
"use client";

/**
 * @fileoverview Global React Query provider setup for the application
 *
 * This file sets up the React Query client provider that should be used across the entire application.
 * It handles both server-side rendering (SSR) and client-side state management properly.
 *
 * IMPORTANT USAGE GUIDELINES:
 *
 * 1. Provider Setup:
 *    - This provider MUST be used at the root level of the application (typically in app/layout.tsx)
 *    - DO NOT create additional QueryClient instances in other parts of the app
 *    - Remove any existing QueryClientProvider in feature-specific layouts
 *
 * 2. Server vs Client Behavior:
 *    - Server: Creates a new QueryClient for each request to prevent cross-request state pollution
 *    - Client: Reuses the same QueryClient instance to maintain consistent cache across route changes
 *
 * 3. Configuration:
 *    - Default staleTime is set to 60 seconds to prevent immediate refetching on client
 *    - Retries are limited to 3 attempts, with special handling for auth failures
 *    - These settings can be overridden in individual useQuery hooks if needed
 *
 * 4. Common Pitfalls:
 *    - DO NOT wrap this provider with Suspense boundaries
 *    - DO NOT create new QueryClient instances in page/component-level code
 *    - Always use this provider for consistent caching behavior
 *
 * Example Usage in app/layout.tsx:
 * ```tsx
 * import Providers from './Providers'
 *
 * export default function RootLayout({ children }) {
 *   return (
 *     <html>
 *       <body>
 *         <Providers>{children}</Providers>
 *       </body>
 *     </html>
 *   )
 * }
 * ```
 *
 * @example
 * // Correct: Using the provider at the root
 * // app/layout.tsx
 * import Providers from './Providers'
 *
 * export default function RootLayout({ children }) {
 *   return <Providers>{children}</Providers>
 * }
 *
 * // Incorrect: Creating a new QueryClient instance
 * // DON'T DO THIS in feature layouts or components:
 * const queryClient = new QueryClient() // ❌ Wrong!
 *
 * @example
 * // Correct: Using queries in components
 * function MyComponent() {
 *   const { data } = useQuery({
 *     queryKey: ['my-data'],
 *     queryFn: fetchData,
 *     // Override default settings if needed
 *     staleTime: 30 * 1000 // 30 seconds
 *   })
 *   return <div>{data}</div>
 * }
 */

// Since QueryClientProvider relies on useContext under the hood, we have to put 'use client' on top
import { isServer, QueryClient, QueryClientProvider } from "@tanstack/react-query";

/**
 * Creates a new QueryClient instance with default configuration
 * @returns {QueryClient} Configured QueryClient instance
 */
function makeQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // With SSR, we usually want to set some default staleTime
        // above 0 to avoid refetching immediately on the client
        staleTime: 60 * 1000,
        retry: (failureCount, error) => {
          if (error instanceof Error && error.message === "Unauthorized") {
            return false; // Don't retry auth failures
          }
          return failureCount < 3;
        },
      },
    },
  });
  return queryClient;
}

// Singleton instance for client-side
let browserQueryClient: QueryClient | undefined = undefined;

/**
 * Gets or creates a QueryClient instance based on environment
 * @returns {QueryClient} QueryClient instance
 */
function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

/**
 * Global provider component for React Query
 * This should be used at the root level of the application
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be wrapped
 * @returns {JSX.Element} Provider wrapped children
 */
export default function Providers({ children }: { children: React.ReactNode }) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
