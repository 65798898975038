import Image from "next/image";
import { UserProfile } from "src/api/types";
import { ClientPopover } from "./ClientPopover";

function extractInitials(name: string): string {
  if (!name) return "";
  const [surName, lastName] = name.split(" ");
  return (surName.slice(0, 1) + (lastName || "").slice(0, 1)).toUpperCase();
}

export function UserAvatar({ user }: Readonly<{ user?: UserProfile }>) {
  if (!user) return null;
  const imageUrl = "https://ui-avatars.com/api/?format=png&name=" + extractInitials(user.name);
  const button = (
    <Image
      className="w-9 h-9 rounded-full object-cover"
      width={24}
      height={24}
      src={imageUrl}
      alt={user.name}
    />
  );
  const panel = (
    <div className="py-1">
      <p className="block px-4 py-2 text-base text-gray-500">{user.email}</p>
      <hr className="border-gray-200" />
      <a
        href="https://forms.clickup.com/f/a23k9-1211/YSJBBEX6VOAJYLGPD1"
        target="_blank"
        rel="noreferrer"
        className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
      >
        Feedback
      </a>
      <a href="/logout" className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100">
        Logout
      </a>
    </div>
  );
  return <ClientPopover button={button} panel={panel} />;
}
