import { z } from "zod";
import { ApiValidationError } from "./errors";

export class ValidationMiddleware {
  static async validateRequest<T extends z.ZodType>(
    endpoint: string,
    schema: T,
    data: unknown,
  ): Promise<z.infer<T>> {
    try {
      return schema.parse(data);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationError = new ApiValidationError("Request", error, endpoint);
        console.error("Request validation failed:", validationError.toJSON());
        throw validationError;
      }
      throw error;
    }
  }

  static async validateResponse<T extends z.ZodType>(
    endpoint: string,
    schema: T,
    data: unknown,
  ): Promise<z.infer<T>> {
    try {
      return schema.parse(data);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationError = new ApiValidationError("Response", error, endpoint);
        console.error("Response validation failed:", validationError.toJSON());
        throw validationError;
      }
      throw error;
    }
  }
}
