import { tokenManager } from "./auth-client";
import { z } from "zod";
import { ValidationMiddleware } from "src/lib/validation/middleware";

interface APIClientConfig {
  baseURL?: string;
}

interface ValidatedRequestConfig<TReq extends z.ZodType, TRes extends z.ZodType>
  extends RequestInit {
  validation?: {
    request?: TReq;
    response?: TRes;
  };
}

export class APIClient {
  private baseURL: string;

  constructor(config?: APIClientConfig) {
    this.baseURL = config?.baseURL || process.env.NEXT_PUBLIC_API_URL || "/";
  }

  private async getAuthToken(): Promise<string | null> {
    // Handle token retrieval for client-side
    if (typeof window !== "undefined") {
      return await tokenManager.getToken();
    }

    // Handle token retrieval for server-side
    const { cookies } = require("next/headers");
    return cookies().get("auth_token")?.value ?? null;
  }

  async fetch<T = any, TReq extends z.ZodType = any, TRes extends z.ZodType = any>(
    endpoint: string,
    init?: ValidatedRequestConfig<TReq, TRes>,
  ): Promise<T> {
    try {
      const token = await this.getAuthToken();
      if (!token) {
        throw new Error("No token available");
      }

      const url = endpoint.startsWith(this.baseURL) ? endpoint : `${this.baseURL}${endpoint}`;

      // Validate request body if schema provided
      let validatedBody = init?.body;
      if (init?.body && init.validation?.request) {
        const requestData = JSON.parse(init.body as string);
        const validatedData = await ValidationMiddleware.validateRequest(
          endpoint,
          init.validation.request,
          requestData,
        );
        validatedBody = JSON.stringify(validatedData);
      }

      const response = await fetch(url, {
        ...init,
        body: validatedBody,
        headers: {
          "Content-Type": "application/json",
          ...init?.headers,
          Authorization: `Token ${token}`,
        },
      });

      if (!response || response.status === 401) {
        throw new Error("Unauthorized");
      }

      if (response.status < 200 || response.status >= 300) {
        const cause = response.headers.get("content-type")?.includes("application/json")
          ? await response.json()
          : { message: response.statusText };
        throw new Error(`API error: ${response.statusText}`, { cause });
      }

      if (response.status === 204) {
        return null as T;
      }

      const responseData = await response.json();

      // Validate response if schema provided
      if (init?.validation?.response) {
        return ValidationMiddleware.validateResponse(
          endpoint,
          init.validation.response,
          responseData,
        ) as T;
      }

      return responseData;
    } catch (error) {
      if (error instanceof Error && error.message === "Unauthorized") {
        // Only clear token on 401 responses
        tokenManager.clearToken();
      }
      throw error;
    }
  }
}
