import { z } from "zod";

export class ApiValidationError extends Error {
  constructor(
    public context: "Request" | "Response",
    public errors: z.ZodError,
    public endpoint: string,
  ) {
    super(`API ${context} Validation Error`);
    this.name = "ApiValidationError";
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      context: this.context,
      endpoint: this.endpoint,
      errors: this.errors.flatten(),
    };
  }
}
