"use client";

import { Popover, PopoverBackdrop, PopoverButton, PopoverPanel } from "@headlessui/react";

export function ClientPopover({
  button,
  panel,
}: Readonly<{ button: React.ReactNode; panel: React.ReactNode }>) {
  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton>{button}</PopoverButton>

          {open && <PopoverBackdrop className="fixed inset-0 bg-gray-300 bg-opacity-50" />}

          <PopoverPanel className="absolute bottom-12 left-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            {panel}
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
}
