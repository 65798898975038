"use client";

import { useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { useProfile } from "src/api/usersClient";
import { UserAvatar } from "./UserAvatar";

const navbarItems = [
  {
    title: "Projects",
    icon: "/pursuits.svg",
    compare: "/projects/",
    href: `/pursuits/dashboard`,
  },
  {
    title: "Services",
    icon: "/services.svg",
    compare: "/services/",
    href: "/services",
  },
  {
    title: "Suppliers",
    icon: "/suppliers.svg",
    compare: "/org-suppliers",
    href: "/org-suppliers/supplier-list",
  },
  {
    title: "Settings",
    icon: "/settings.svg",
    compare: "/supplier-settings/",
    href: "/supplier-settings/profile-form",
  },
  {
    title: "Help",
    icon: "/help.svg",
    compare: "n/a",
    href: "https://help.caltana.com",
  },
];

type SidebarItem = {
  title: string;
  icon: string;
  compare: string;
  href: string;
};

function SidebarListItem({
  nav,
  showSidebar,
}: Readonly<{
  nav: SidebarItem;
  showSidebar: boolean;
}>) {
  const pathname = usePathname();

  return (
    <li key={nav.title} className={`my-4 } `}>
      <Link
        href={nav.href}
        className={`
          flex items-center no-underline text-black px-2 py-1 rounded-sm text-base
          ${
            pathname.includes(nav.compare)
              ? "bg-purple-100 hover:bg-purple-100"
              : "hover:bg-purple-100"
          }
          `}
      >
        <Image src={nav.icon} width={20} height={21} alt={`${nav.title} icon`} />
        <span
          className={`transition-width duration-200 ease-in-out ${
            showSidebar ? "block ml-4" : "hidden"
          }`}
        >
          {nav.title}
        </span>
      </Link>
    </li>
  );
}

export function Sidebar({
  smallLogo,
  largeLogo,
}: Readonly<{
  smallLogo: React.ReactNode;
  largeLogo: React.ReactNode;
}>) {
  const [showSidebar, setShowSidebar] = useState(false);
  const { data: user } = useProfile();

  return (
    <nav
      className={`
        fixed left-0 top-0
        transition-width duration-200 ease-in-out 
        bg-purple-50 z-10 
        px-2 py-5 flex flex-col items-start
        ${showSidebar ? "w-44" : "w-16"} h-screen
        `}
      onMouseLeave={() => setShowSidebar(false)}
      onMouseEnter={() => setShowSidebar(true)}
    >
      <Link href="/" className="px-2">
        <div className={`${showSidebar ? "hidden" : "block"}`}>{smallLogo}</div>
        <div className={`${showSidebar ? "block" : "hidden"}`}>{largeLogo}</div>
      </Link>
      <ul className="list-none p-0">
        {navbarItems.map((nav) => (
          <SidebarListItem key={nav.title} nav={nav} showSidebar={showSidebar} />
        ))}
      </ul>
      <div className="fixed bottom-3 left-3">
        <div className={`flex items-center space-x-3`}>
          <UserAvatar user={user} />
          <div
            className={`transition-width duration-200 ease-in-out max-w-[120px] ${
              showSidebar ? "block" : "hidden"
            }`}
          >
            <div className="font-medium text-gray-700 truncate">{user?.name}</div>
            <div className="text-sm text-gray-500 truncate">{user?.organization.name}</div>
          </div>
        </div>
      </div>
    </nav>
  );
}
